<!--
 * @Author: lzy
 * @Date: 2022-09-14 16:38:12
 * @LastEditors: lzy
 * @LastEditTime: 2023-03-09 09:42:19
-->
<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script setup></script>
<style>
body {
  background: #eeeeee;
}
:root {
  --van-button-small-height: 0.8rem !important;
  --van-switch-height: calc(0.5rem + 4px) !important;
  --van-switch-width: calc(1rem + 4px) !important;
  --van-switch-node-size: calc(0.5rem) !important;
  --van-cell-vertical-padding: 0.256rem !important;
  --van-font-size-md: 0.4rem !important;
  --van-button-mini-height: 0.5rem !important;
  --van-button-mini-padding: 0.1rem !important;
  --van-cell-line-height: 0.5rem !important;
  --van-cell-icon-size: 0.5rem !important;
  --van-stepper-input-height: 0.6rem !important;
  --van-stepper-input-width: 0.6rem !important;
  --van-checkbox-size: 0.5rem !important;
  --van-dialog-width: 75vw !important;
  --van-dialog-font-size: 0.5rem !important;
  --van-dialog-message-max-height: unset !important;
  --van-dialog-message-line-height: unset !important;
  --van-dialog-header-line-height: unset !important;
  --van-dialog-button-height: 1rem !important;
  --van-picker-toolbar-height: 1rem !important;
  --van-toast-line-height: 0.5rem !important;
  --van-toast-default-width: fit-content !important;
}
</style>
