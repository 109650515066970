/*
 * @Author: lzy
 * @Date: 2022-09-14 16:38:12
 * @LastEditors: lzy
 * @LastEditTime: 2023-03-08 09:53:23
 */
import {
  createRouter,
  createWebHashHistory,
} from 'vue-router';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login.vue')
    },
    {
      path: '/',
      name: 'Index',
      component: () => import('@/views/index.vue')
    },
  ]
});



export default router;
