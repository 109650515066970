/*
 * @Author: lzy
 * @Date: 2022-09-14 16:38:12
 * @LastEditors: lzy
 * @LastEditTime: 2023-03-03 11:18:15
 */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { setAxiosBase } from '@/utils/service';
import Vant from 'vant'
import 'amfe-flexible'
import 'vant/lib/index.css'

setAxiosBase();

const app = createApp(App);
app.use(router);
app.use(Vant)
app.mount('#app');
