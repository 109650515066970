/*
 * @Author: lzy
 * @Date: 2022-09-14 16:38:12
 * @LastEditors: lzy
 * @LastEditTime: 2023-03-08 15:01:23
 */
import axios from "axios";
import { showToast } from "vant";

const handleRespone = async (response) => {
  console.log(response);
  if (response.status === 200) {
    if (response.data) {
      if (response.data.errcode && response.data.errcode != 0) {
        if (!(response.config.url.indexOf("/webapi/get_my_twitter") > -1)) {
          showToast(response.data.msg || "网络错误");
        }
        return Promise.reject(response.data.msg || "网络错误");
      }
      return Promise.resolve(response.data);
    }
  }

  return Promise.resolve(response.data);
};

const handlerError = async (error) => {
  console.log(error.response);
  if (error.response) {
    if (error.response.data) {
      if (
        error.response.data.errcode == "-1" &&
        !(error.response.config.url.indexOf("/webapi/get_my_twitter") > -1)
      ) {
        if (error.response.data.msg) {
          showToast(error.response.data.msg);
        }
      }
    }
  }

  return Promise.reject(error.response);
};

const handleRequest = async (config) => {
  let token = "";
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

export function setAxiosBase() {
  axios.defaults.headers.common["Content-Type"] =
    "application/json; charset=utf-8";
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(handleRequest);
  axios.interceptors.response.use(handleRespone, handlerError);
}
